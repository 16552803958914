module.exports = [{
      plugin: require('/var/www/html/tastyworks-aus.caxy.com/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":""},
    },{
      plugin: require('/var/www/html/tastyworks-aus.caxy.com/node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WB4TWN5","timeout":3000,"includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"function() {\n          return {\n            pageType: window.pageType,\n          }\n        }"}},
    },{
      plugin: require('/var/www/html/tastyworks-aus.caxy.com/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/www/html/tastyworks-aus.caxy.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
